import React, { useCallback, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
var AGE_SCALE = 80;
var VB_HEIGHT = 600;
var VB_WIDTH = 800;
var RADIUS = 200;
var BOLDNESS = 20;
var LOCAL_STORAGE_KEY = "birthYear";
document.addEventListener('DOMContentLoaded', function () {
    var root = createRoot(document.getElementById("app"));
    root.render(<App />);
});
function App() {
    var _a = useState(), error = _a[0], setError = _a[1];
    var _b = useState(), remaining = _b[0], setRemaining = _b[1];
    var _c = useState(), inputBirthYear = _c[0], setInputBirthYear = _c[1];
    var _d = useState(0), x = _d[0], setX = _d[1];
    var _e = useState(0), y = _e[0], setY = _e[1];
    var calcChange = useCallback(function (inputBirthYear) {
        setInputBirthYear(inputBirthYear);
        setError(undefined);
        setRemaining(undefined);
        setX(0);
        setY(0);
        var currentYear = new Date().getFullYear();
        var birthYear = Number.parseInt(inputBirthYear !== null && inputBirthYear !== void 0 ? inputBirthYear : "");
        // It's very unlikely to be born before 1900 or after 2100. In these cases error message is tedious, just ignore the input.
        if (birthYear > 2100 || birthYear < 1900) {
            return;
        }
        if (Number.isNaN(birthYear)) {
            setError("invalid value");
            return;
        }
        var diff = Math.max((birthYear + AGE_SCALE) - currentYear, 0);
        if (diff > AGE_SCALE) {
            setError("you are not born yet.");
            return;
        }
        // looks like the input is valid birthday...
        setRemaining(diff.toString());
        // save for later accesses
        localStorage.setItem(LOCAL_STORAGE_KEY, birthYear.toString());
        // FIXME: use radian
        // diff : scale = r : 360
        console.log("s=".concat(AGE_SCALE - diff));
        console.log("ratio=".concat((360 * (AGE_SCALE - diff) / AGE_SCALE)));
        // Minus 450 to fix to clock-angle
        var r = 450 - (360 * (AGE_SCALE - diff) / AGE_SCALE);
        console.log("ab_ratio=".concat(r > 360 ? r - 360 : r));
        var x = Math.cos(r * Math.PI / 180) * RADIUS;
        var y = Math.sin(r * Math.PI / 180) * RADIUS;
        console.log("x=".concat(x));
        console.log("y=".concat(y));
        setX(x);
        setY(y);
    }, []);
    var handleChange = useCallback(function (e) {
        calcChange(e.target.value);
    }, []);
    useEffect(function () {
        var saved = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (saved) {
            console.log("read from localstorage");
            calcChange(saved);
        }
    }, []);
    return (<div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <svg viewBox={"0 0 ".concat(VB_WIDTH, " ").concat(VB_HEIGHT)} style={{ maxWidth: "min(90vw, 90vh)" }}>
          <circle cx={VB_WIDTH / 2} cy={VB_HEIGHT / 2} r={RADIUS} fill="none" stroke="black" strokeWidth={BOLDNESS}/>
          {!error && x && y && (<line x1={VB_WIDTH / 2} y1={VB_HEIGHT / 2} x2={x + (VB_WIDTH / 2)} y2={((VB_HEIGHT / 2) - y)} stroke="black" strokeWidth={BOLDNESS}/>)}
        </svg>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <div>
            {remaining && <p>You have {remaining} years left, if you die at 80.</p>}

            <label htmlFor="birth-year">Your birth year: </label>
            <input id="birth-year" type="text" value={inputBirthYear} onChange={handleChange}/>
            {error && <p>{error}</p>}
          </div>
          <div>
            <a href="https://github.com/minebreaker/mmtm">GitHub</a>
          </div>
        </div>
      </div>
    </div>);
}
